import {Controller} from "@hotwired/stimulus";
import Gallery from "blueimp-gallery";
import "blueimp-gallery/js/blueimp-gallery-indicator";

export default class extends Controller {
    initialize() {
    }

    connect() {
        console.log('controller gallery connected!');
        const gallery = document.getElementById('links');
        if (gallery) {
            gallery.onclick = function (event) {
                event = event || window.event
                let target = event.target || event.srcElement
                let link = target.src ? target.parentNode : target

                let options = {
                    index: link,
                    event: event,
                    container: "#blueimp-gallery",
                    onslide: function (index, slide) {
                        $("#blueimp-gallery").children('.description').html($('#links a').eq(index).data('description'));
                    }
                }

                let links = this.getElementsByTagName('a')
                Gallery(links, options)
            }
        }

        const multiplyGallery = document.querySelectorAll('[id^="links-"]');

        if (multiplyGallery) {
            multiplyGallery.forEach(el => {
                el.onclick = function (event) {
                    event = event || window.event
                    let target = event.target || event.srcElement
                    let link = target.src ? target.parentNode : target
                    let gallery = $(link).data('gallery');
                    // let description =  $(link).data('description');

                    // $('#' + gallery).children('.description').html(description)

                    let options = {
                        index: link,
                        event: event,
                        container: "#" + gallery,
                        onslide: function (index, slide) {
                            $("#" + gallery).children('.description').html($('#links a').eq(index).data('description'));
                        }
                    }

                    let links = this.getElementsByTagName('a')
                    Gallery(links, options)
                }
            });
        }
    }
}