import {Controller} from "@hotwired/stimulus";
import {Popover} from "bootstrap"

export default class extends Controller {
    initialize() {
    }

    connect() {
        console.log('controller popover connected!');

        let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))

        let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new Popover(popoverTriggerEl, {html: true})
        })
    }
}