import {Controller} from "@hotwired/stimulus"
import {Datepicker} from 'vanillajs-datepicker';
import et from 'vanillajs-datepicker/locales/et';

export default class extends Controller {
    initialize() {
        Object.assign(Datepicker.locales, et);
    }

    connect() {
        console.log('controller filter connected!');
        const startAt = document.getElementById('kp_from');
        const endAt = document.getElementById('kp_to');

        if (startAt) {
            new Datepicker(startAt, {
                language: 'et',
                buttonClass: 'btn'
            });
        }

        if (endAt) {
            new Datepicker(endAt, {
                language: 'et',
                buttonClass: 'btn'
            });
        }

        const contractStartAt = document.getElementById('kp2_from');
        if (contractStartAt) {
            new Datepicker(contractStartAt, {
                language: 'et',
                buttonClass: 'btn'
            });
        }

        const contractEndAt = document.getElementById('kp2_to');

        if (contractEndAt) {
            new Datepicker(contractEndAt, {
                language: 'et',
                buttonClass: 'btn'
            });
        }

        const createdStartAt = document.getElementById('q_lisatud_kp_gteq');
        const createdEndAt = document.getElementById('q_lisatud_kp_lteq');

        if (createdStartAt) {
            new Datepicker(createdStartAt, {
                language: 'et',
                buttonClass: 'btn'
            });
        }

        if (createdEndAt) {
            new Datepicker(createdEndAt, {
                language: 'et',
                buttonClass: 'btn'
            });
        }


        let form = $('[role="form"]');
        if (form) {
            form.find('select').each((index, el) => {
                if (el.hasAttribute('data-parent')) {
                    const parent = $(el.getAttribute('data-parent'));
                    if (parent.val() === '' || parent.val() === undefined) {
                        $(el).attr('disabled', 'disabled');
                    }
                }
            });
        }

        $('[data-parent]').each((index, el) => {
            const parent = $(el).data('parent')
            if (parent && $(parent)) {
                $(parent).on('change', event => this.onChangeParent(event));
            }
        });

        const quickSelectDate = $('[data-role~=date-quick-select]');

        if (quickSelectDate) {
            quickSelectDate.on('click', event => this.selectDates(event));
        }
    }

    toggle() {
        $(".kvr-filter-form").toggleClass("kvr-form-hidden");
    }

    onChangeParent(event) {
        const parent = $(event.target);
        const children = $(`[data-parent="#${parent.attr('id')}"]`);

        children.each((index, el) => {
            const child = $(el);
            child.closest('div').removeClass('has-error');

            if (child.is('select')) {
                child.empty();
            } else {
                child.val('');
            }

            let url = child.data('url');

            if (url) {
                const value = parent.val();

                if (child.is("select")) {
                    child.attr('disabled', 'disabled');
                    child.append($('<option></option>'));
                }

                child.trigger('change');

                if (value) {
                    url += `?${parent.attr("id")}=${value}`;
                    $.ajax({
                        url: url,
                        type: 'GET',
                        dataType: 'json',
                        success: (data) => {
                            for (const option of data) {
                                child.append(new Option(option.value, option.id));
                                child.removeAttr('disabled');
                            }
                        }
                    });
                }
            }
        });
    }

    onChangeObjectType(event) {
        this.hideObjectTypes();
        switch (event.target.value) {
            case '41': {
                break;
            }
            case '43': {
                $(".filter-cadastral").removeClass('d-none');
                $(".filter-land-unit-type").removeClass('d-none');
                break;
            }
            case '46': {
                $(".filter-cadastral").removeClass('d-none');
                $(".filter-land-type").removeClass('d-none');
                $(".filter-land-no").removeClass('d-none');
                break;
            }
            case '40': {
                $(".filter-room-type").removeClass('d-none');
                break;
            }
            default: {
                this.hideObjectTypes();
                break;
            }
        }
    }

    onChangeRoomType(event) {
        $(".filter-livingroom-type").addClass('d-none');
        $(".filter-livingroom-rooms").addClass('d-none');
        switch (event.target.value) {
            case '84': {
                $(".filter-livingroom-type").removeClass('d-none');
                $(".filter-livingroom-rooms").removeClass('d-none');
                break;
            }
        }
    }

    hideObjectTypes() {
        $("div[class^='filter-']").each((index, element) => {
            $(element).addClass('d-none');
        });
    }

    reset(event) {
        event.preventDefault();
        const element = $(event.target);
        const form = element.closest('[role="form"]');

        if (form) {
            form.find('input[type=text], select').each((index, el) => {
                const input = $(el);
                if (input.is('select')) {
                    if (input.data('default-value')) {
                        if (`${input.val()}` !== `${input.data('default-value')}`) {
                            input.val(input.data('default-value'));
                            input.trigger('change');
                        }
                    } else {
                        input.find(':first-child').first().attr('selected', 'selected');
                        input.val(input.data(''));
                        input.trigger('change');
                    }
                } else {
                    input.closest('div').removeClass('has-error');
                    input.val('');
                }
            });
        }
    }

    selectDates(event) {
        event.preventDefault();
        const element = $(event.target);
        $(element.data('from')).val(element.data('from-value'))
        $(element.data('to')).val(element.data('to-value'))
    }
}