import {Controller} from "@hotwired/stimulus";
import {Modal} from 'bootstrap';

export default class extends Controller {
    initialize() {

    }

    connect() {
        console.log('controller settings connected!');

        const modal = new Modal(document.getElementById('settingsModal'), {
            keyboard: false
        });

        $("#open-settings").on('click', () => {
            modal.show();
        });

        $('#edit-person-contacts').on('click', function () {
            console.log('edit person contacts');
            $('#edit-person-contacts').hide();
            $('#newSessionModalLabel').hide();
            $('#close-modal').hide();

            $('#submit-person-contacts').show();
            $('#cancel-edit').show();
            $('#edit-title').show();
            $('#email-row').show();
            $('#phone-row').show();
            $('#info-agreement').show();
        });

        $('#cancel-edit').on('click', function () {
            $('#submit-person-contacts').hide();
            $('#cancel-edit').hide();
            $('#edit-title').hide();
            $('#email-row').hide();
            $('#phone-row').hide();
            $('#info-agreement').hide();

            $('#edit-person-contacts').show();
            $('#newSessionModalLabel').show();
            $('#close-modal').show();
        });

        let isDisabled = true;

        $('input[name=email]').on('input', function (e) {
            const email = $('#email');
            if (isEmail(e.target.value)) {
                $('#submit-person-contacts').prop('disabled', false);
                email.removeClass('is-invalid');
                isDisabled = false;
            } else {
                $('#submit-person-contacts').prop('disabled', true);
                email.addClass('is-invalid');
                isDisabled = true;
            }
        });

        $('input[name=phone]').on('input', function (e) {
            const phone = $('#phone');
            if (e.target.value.length > 5) {
                $('#submit-person-contacts').prop('disabled', false);
                phone.removeClass('is-invalid');
                isDisabled = false;
            } else {
                $('#submit-person-contacts').prop('disabled', true);
                phone.addClass('is-invalid');
                isDisabled = true;
            }
        });

        $('input[name=is_agree]').on('input', function (e) {
            const isAgree = $('#is_agree');
            const info = $('#info-agreement');
            if (e.target.checked) {
                $('#submit-person-contacts').prop('disabled', false);
                isAgree.removeClass('is-invalid');
                info.removeClass('has-error');
                isDisabled = false;
            } else {
                $('#submit-person-contacts').prop('disabled', true);
                isAgree.addClass('is-invalid');
                info.addClass('has-error');
                isDisabled = true;
            }
        });

        function isEmail(email) {
            let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
            return regex.test(email);
        }

        $('#submit-person-contacts').on('click', () => {
            const isAgree = $('#is_agree').is(':checked');

            if (isAgree) {
                const form = $("#form-settings").serializeArray();
                let json = {};
                $.each(form, function () {
                    if (json[this.name]) {
                        if (!json[this.name].push) {
                            json[this.name] = [json[this.name]];
                        }
                        json[this.name].push(this.value || '');
                    } else {
                        json[this.name] = this.value || '';
                    }
                });

                const url = '/api/settings';
                $.ajax({
                    url: url,
                    type: 'PUT',
                    dataType: 'json',
                    data: json,
                    success: (data) => {
                        $('#alert').removeClass('d-none');
                        $('#submit-person-contacts').hide();
                        $('#cancel-edit').hide();
                        $('#edit-title').hide();
                        $('#email-row').hide();
                        $('#phone-row').hide();
                        $('#info-agreement').hide();

                        $('#edit-person-contacts').show();
                        $('#newSessionModalLabel').show();
                        $('#close-modal').show();
                    }
                });
            } else {
                $('#info-agreement').addClass('has-error');
                $('#is_agree').addClass('is-invalid');
            }
        });
    }
}