import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    initialize() {
    }

    connect() {
        $('.table-sorter').DataTable({
            bPaginate: $('.table-sorter').data('paginate') || false,
            bFilter: false,
            bInfo: false,
            iDisplayLength: 50,
            sPaginationType: 'bootstrap',
            bLengthChange: false,
            oLanguage: {
                sSearch: "",
                sZeroRecords: "&Uuml;htegi kirjet ei leitud",
                oPaginate: {
                    sPrevious: 'Eelmine',
                    sNext: 'Järgmine'
                }
            }
        });
    }

    click() {
        console.log('properties_controller');
    }
}
