import "popper.js";
import "bootstrap";
import jquery from 'jquery'

window.jQuery = jquery
window.$ = jquery

import 'datatables.net-bs5';
import 'blueimp-gallery';

import "./controllers";