import {Controller} from "@hotwired/stimulus";
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";

import oms from "./oms";

export default class extends Controller {
    initialize() {
    }

    connect() {
        console.log('controller map connected!');

        const mapData = $("#map");
        if (mapData.length !== 0) {
            const data = mapData.data('markers');
            if (data.length >= 1) {
                const center = {
                    lat: data[0]?.lat,
                    lng: data[0]?.lng
                };
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 15,
                    center: center
                });

                const overlay = new oms.OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,
                    markersWontHide: true,
                    keepSpiderfied: true
                });
                const infoWindow = new google.maps.InfoWindow();

                overlay.addListener('click', (marker, event) => {
                    infoWindow.setContent(marker.desc)
                    infoWindow.open(map, marker)
                });


                overlay.addListener('spiderfy', (markers) => {
                    infoWindow.close();
                });

                const markers = [];
                const bounds = new google.maps.LatLngBounds();

                data.forEach(item => {
                    if (item.lat !== null && item.lng !== null) {
                        const marker = new google.maps.Marker({
                            position: {
                                lat: item.lat,
                                lng: item.lng
                            },
                            map: map,
                            icon: item.picture.url
                        });

                        marker.desc = item.infowindow;

                        const position = new google.maps.LatLng(item.lat, item.lng);
                        bounds.extend(position);

                        marker.addListener("click", event => {
                            event.domEvent.preventDefault();
                            infoWindow.open({
                                anchor: marker,
                                map,
                                shouldFocus: false,
                            });
                        });

                        overlay.addMarker(marker)
                        markers.push(marker);
                    }
                });

                const algorithm = new SuperClusterAlgorithm({maxZoom: 18, radius: 120})
                new MarkerClusterer({markers, map, algorithm});
                map.fitBounds(bounds);
            }
        }

        $('.google-tab a[data-bs-target="#gmap"]')
            .on('show.bs.tab', event => this.onTabChange(event));

        const multiMaps = $('[id^=map-]');
        if (multiMaps.length > 0) {
            multiMaps.each((index, element) => {
                const data = $(element).data('markers');
                if (data.length >= 1) {
                    const center = {
                        lat: data[0]?.lat,
                        lng: data[0]?.lng
                    };

                    const map = new google.maps.Map(document.getElementById(element.id), {
                        zoom: 15,
                        center: center
                    });

                    const overlay = new oms.OverlappingMarkerSpiderfier(map, {
                        markersWontMove: true,
                        markersWontHide: true,
                        keepSpiderfied: true
                    });
                    const infoWindow = new google.maps.InfoWindow();

                    overlay.addListener('click', (marker, event) => {
                        infoWindow.setContent(marker.desc)
                        infoWindow.open(map, marker)
                    });


                    overlay.addListener('spiderfy', (markers) => {
                        infoWindow.close();
                    });

                    const markers = [];
                    const bounds = new google.maps.LatLngBounds();

                    data.forEach(item => {
                        if (item.lat !== null && item.lng !== null) {
                            const marker = new google.maps.Marker({
                                position: {
                                    lat: item.lat,
                                    lng: item.lng
                                },
                                map: map,
                                icon: item.picture.url
                            });

                            marker.desc = item.infowindow;

                            const position = new google.maps.LatLng(item.lat, item.lng);
                            bounds.extend(position);

                            marker.addListener("click", event => {
                                event.domEvent.preventDefault();
                                infoWindow.open({
                                    anchor: marker,
                                    map,
                                    shouldFocus: false,
                                });
                            });

                            overlay.addMarker(marker)
                            markers.push(marker);
                        }
                    });

                    const algorithm = new SuperClusterAlgorithm({maxZoom: 18, radius: 120})
                    new MarkerClusterer({markers, map, algorithm});
                    // map.fitBounds(bounds);
                }
            });
        }
    }

    onTabChange(event) {
        $('#spinner').show();
        $('#google-map').hide();
        if ($(event.target).data('url')) {
            const url = '/api/services/' + $(event.target).data('url');
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                success: (data) => {
                    const center = {
                        lat: data[0]?.lat,
                        lng: data[0]?.lng
                    };

                    const map = new google.maps.Map(document.getElementById("google-map"), {
                        zoom: 10,
                        center: center
                    });

                    const overlay = new oms.OverlappingMarkerSpiderfier(map, {
                        markersWontMove: true,
                        markersWontHide: true,
                        keepSpiderfied: true
                    });
                    const infoWindow = new google.maps.InfoWindow();

                    overlay.addListener('click', (marker, event) => {
                        infoWindow.setContent(marker.desc)
                        infoWindow.open(map, marker)
                    });


                    overlay.addListener('spiderfy', (markers) => {
                        infoWindow.close();
                    });

                    const markers = [];
                    const bounds = new google.maps.LatLngBounds();

                    data.forEach(item => {
                        if (item.lat !== null && item.lng !== null) {
                            const marker = new google.maps.Marker({
                                position: {
                                    lat: item.lat,
                                    lng: item.lng
                                },
                                map: map,
                                icon: item.picture.url
                            });

                            marker.desc = item.infowindow;

                            const position = new google.maps.LatLng(item.lat, item.lng);
                            bounds.extend(position);

                            marker.addListener("click", event => {
                                infoWindow.open({
                                    anchor: marker,
                                    map,
                                    shouldFocus: false,
                                });
                            });

                            overlay.addMarker(marker)
                            markers.push(marker);
                        }
                    });

                    const algorithm = new SuperClusterAlgorithm({maxZoom: 18, radius: 120})
                    new MarkerClusterer({markers, map, algorithm});
                    map.fitBounds(bounds);

                    $('#spinner').hide();
                    $('#google-map').show();
                }
            });
        }
    }
}